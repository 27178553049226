import React from "react"
import { graphql } from 'gatsby'
import { Grid, Box, Text, Image } from "theme-ui"
import ProductTemplate from "../../components/store/ProductTemplate"
import UpgradeProgramForm from "../../components/store/UpgradeProgramForm"
import BuyButton from "../../components/store/BuyButton"

export const query = graphql`
  query {
    contentfulProduct(slug: { eq: "digital-edition" }) {
      status
      name
      slug
      price
      title
      images { file { url } }
      description { json }
      componentId
      productId
      relatedProducts {
        slug
      }
    }
  }
`

const ProductDigitalEdition = props => {
  return (
    <ProductTemplate
      name={props.data.contentfulProduct.name}
      slug={props.data.contentfulProduct.slug}
      status={props.data.contentfulProduct.status}
      title={props.data.contentfulProduct.title}
      price={props.data.contentfulProduct.price}
      description={props.data.contentfulProduct.description.json}
      images={
        props.data.contentfulProduct.images.map((image, i) =>
          <Image src={props.data.contentfulProduct.images[i].file.url} key={i} />
        )
      }
      buyButton={
        <BuyButton componentId={props.data.contentfulProduct.componentId} productId={props.data.contentfulProduct.productId} price={props.data.contentfulProduct.price} />
      }
      relatedProducts={
        props.data.contentfulProduct.relatedProducts.map((product, i) => 
          props.data.contentfulProduct.relatedProducts[i].slug
        )
      }
//       extra={  
//         <Grid variant="base" gap={[4, 6, 8]} columns={[1, 2]} sx={{ overflowX: "visible" }}>
//           <Box>
//             <Text as="h2" variant="title02">Upgrade Program</Text>
//             <Text as="p" variant="body">If you previously purchased a Digital Edition of Quest, we will deduct the full cost from your purchase of a physical Game Book. Please provide your email and Digital Edition order number in this form to request your discount code.</Text>
//           </Box>
          
//           <UpgradeProgramForm />
//         </Grid>
//       }
    />
  )
}

export default ProductDigitalEdition
